@use "../util/" as *;

.nav {
  flex-basis: 20rem;
  flex-grow: 1;
  padding: 3rem 0 3rem 3rem;

  @include breakpoint(large) {
    position: sticky;
    top: 0;
    height: 100vh;
  }

  @include breakpoint-down(small) {
    flex-basis: 100%;
    padding: 1rem;
  }

  &__logo {
    margin-bottom: 3rem;

    @include breakpoint-down(small) {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    img {
      width: 170px;

      @include breakpoint-down(small) {
        width: 50%;
      }
    }
  }

  &__menu {

    ul {
      @include breakpoint-down(small) {
        text-align: center;
      }
    }

    li {
      @include breakpoint-down(small) {
        display: inline;
        
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  a {
    transition: 0.3s ease-in-out;
  }

  a:hover {
    opacity: 0.8;
  }
}

.content {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 50%;
  padding: 3rem 3rem 3rem 0;

  @include breakpoint-down(small) {
    padding: 1rem;
  }

  &__video {
    margin-bottom: 0.75rem;
  }

  &__info {
    padding: 1rem 0;
  }

  &__contact {
    @include breakpoint(medium) {
      margin-top: 7.75rem;
    }
  }

  a {
    transition: 0.3s ease-in-out;
  }

  a:hover {
    opacity: 0.8;
  }
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
