html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-haas-text);
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.4;
  background: var(--black);
  color: var(--white);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

h1,
h2,
h3 {
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
  color: inherit;
  transition: 3s ease-in-out;
}

a:hover {
  opacity: 0.8;
}

#active {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0 0 0.25rem 0;

  li {
    margin-bottom: 0.25rem;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30ch;
}
