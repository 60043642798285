:root {
  --font-haas-text: "neue-haas-grotesk-text", sans-serif;
  --font-brokenplane: "Broken Planewing";
}

:root {
  --black: rgb(20, 20, 20);
  --white: rgb(230, 230, 230);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-haas-text);
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.4;
  background: var(--black);
  color: var(--white);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

h1,
h2,
h3 {
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: 3s ease-in-out;
}

a:hover {
  opacity: 0.8;
}

#active {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0 0 0.25rem 0;
}
ul li {
  margin-bottom: 0.25rem;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30ch;
}

.background {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.single-item {
  width: 100%;
  z-index: 0;
}

img {
  width: 100%;
  height: 100%;
}

.noise {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.5s infinite;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  overflow: hidden;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}
.slick-slide {
  min-height: 100vh;
  object-fit: cover;
}

.splash-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  width: 230px;
}
.splash-logo a:hover {
  opacity: 1;
}
@media (max-width: 39.9375em) {
  .splash-logo {
    width: 190px;
  }
}

@media (max-width: 39.9375em) {
  .img-left {
    object-position: 20% center;
  }
}

@media (max-width: 39.9375em) {
  .img-right {
    object-position: 70% center;
  }
}

.nav {
  flex-basis: 20rem;
  flex-grow: 1;
  padding: 3rem 0 3rem 3rem;
}
@media (min-width: 64em) {
  .nav {
    position: sticky;
    top: 0;
    height: 100vh;
  }
}
@media (max-width: 39.9375em) {
  .nav {
    flex-basis: 100%;
    padding: 1rem;
  }
}
.nav__logo {
  margin-bottom: 3rem;
}
@media (max-width: 39.9375em) {
  .nav__logo {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}
.nav__logo img {
  width: 170px;
}
@media (max-width: 39.9375em) {
  .nav__logo img {
    width: 50%;
  }
}
@media (max-width: 39.9375em) {
  .nav__menu ul {
    text-align: center;
  }
}
@media (max-width: 39.9375em) {
  .nav__menu li {
    display: inline;
  }
  .nav__menu li:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.nav a {
  transition: 0.3s ease-in-out;
}
.nav a:hover {
  opacity: 0.8;
}

.content {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 50%;
  padding: 3rem 3rem 3rem 0;
}
@media (max-width: 39.9375em) {
  .content {
    padding: 1rem;
  }
}
.content__video {
  margin-bottom: 0.75rem;
}
.content__info {
  padding: 1rem 0;
}
@media (min-width: 40em) {
  .content__contact {
    margin-top: 7.75rem;
  }
}
.content a {
  transition: 0.3s ease-in-out;
}
.content a:hover {
  opacity: 0.8;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}