@use "../util/" as *;

@use "../util/" as *;

.background {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.single-item {
  width: 100%;
  z-index: 0;
}

img {
  width: 100%;
  height: 100%;
}

.noise {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.5s infinite;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  overflow: hidden;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}

.slick-slide {
  min-height: 100vh;
  object-fit: cover;
}

.splash-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  width: 230px;

  a:hover {
    opacity: 1;
  }

  @include breakpoint-down(small) {
    width: 190px;
  }
}

.img-left {
  @include breakpoint-down(small) {
    object-position: 20% center;
  }
}

.img-right {
  @include breakpoint-down(small) {
    object-position: 70% center;
  }
}
